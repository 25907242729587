import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "react-rainbow-components";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Button from "@mui/material/Button";

import { PageHeader, Title, Subtitle } from "./styles";

const Occurrences = () => {
  const [occurrences, setOccurrences] = useState([]);

  const { trips } = useSelector((state) => state);
  const [date, setDate] = useState(new Date());
  const [filter, setFilter] = useState("all");
  const [driver, setDriver] = useState("");
  const { REACT_APP_BASE_URL } = process.env;

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(`${REACT_APP_BASE_URL}/occurrences`);
      setOccurrences(response.data.occurrences);
    }
    fetchData();
  }, []);

  const navigate = useNavigate();

  function getDriver(trips, id) {
    return trips.find((trip) => trip.id === id).driver;
  }

  function getCar(trips, id) {
    return trips.find((trip) => trip.id === id).car;
  }

  function getStatus(value) {
    if (value.includes("[[OK]]")) {
      return "solved";
    }
    return "unsolved";
  }

  function filterOccurrence(occurrence) {
    if (filter === "all") {
      return true;
    }
    if (filter === "solved") {
      return getStatus(occurrence.description) === "solved";
    }
    if (filter === "unsolved") {
      return getStatus(occurrence.description) === "unsolved";
    }
    if (filter === "date") {
      if (
        occurrence.createdAt.split("T")[0] === date.toISOString().split("T")[0]
      ) {
        return true;
      }
      return false;
    }

    if (filter === "driver") {
      if (getDriver(trips.data, occurrence.trip_id).includes(driver)) {
        return true;
      }
      return false;
    }

    if (filter === "driverdate") {
      if (
        getDriver(trips.data, occurrence.trip_id).includes(driver) &&
        occurrence.createdAt.split("T")[0] === date.toISOString().split("T")[0]
      ) {
        return true;
      }
      return false;
    }

    return true;
  }

  function goToDetails(id) {
    const trip = trips.data.find((trip) => trip.id === id);
    navigate("/details", { state: { data: trip, from: "occurrences" } });
  }
  return (
    <div
      style={{
        padding: "0px 1rem",
        width: "100%",
      }}
    >
      <br />
      <br />
      <PageHeader>
        <Title>Ocorrências</Title>
        <Subtitle>Lista de ocorrências</Subtitle>
      </PageHeader>

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Filtrar por</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          value={filter}
          fullWidth={true}
          label="Filtrar por"
        >
          <MenuItem value="all">Todos</MenuItem>
          <MenuItem value="date">Data</MenuItem>
          <MenuItem value="solved">Resolvidas</MenuItem>
          <MenuItem value="unsolved">Não resolvidas</MenuItem>
          <MenuItem value="driver">Motorista</MenuItem>
          <MenuItem value="driverdate">Motorista e data</MenuItem>
        </Select>

        {(filter === "driver" || filter === "driverdate") && (
          <>
            <br />
            <TextField
              label="Filtrar por motorista"
              fullWidth={true}
              type="text"
              value={driver}
              onChange={(e) => setDriver(e.target.value)}
              autoComplete="off"
            />
          </>
        )}
      </FormControl>

      {(filter === "driverdate" || filter === "date") && (
        <>
          <DatePicker
            style={{
              marginTop: ".8rem",
            }}
            id="datePicker-1"
            value={date}
            onChange={(d) => {
              setDate(d);
            }}
            label="DatePicker Label"
            formatStyle="large"
            locale="pt-BR"
            isCentered={true}
            hideLabel={true}
          />
        </>
      )}
      <Paper
        style={{
          width: "100%",
          height: "calc(100vh - 350px)",
          overflowY: "auto",
          overflowX: "hidden",
          borderBottom: "transparent",
          boxShadow: "none",
        }}
      >
        <List>
          {occurrences.map((occurrence) => {
            return (
              filterOccurrence(occurrence) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px 10px",
                  }}
                >
                  <ListItem key={occurrence.id}>
                    <ListItemText
                      primary="Data"
                      secondary={occurrence.createdAt.split("T")[0]}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <ListItemText
                      primary="Motorista"
                      secondary={getDriver(trips.data, occurrence.trip_id)}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <ListItemText
                      primary="Veículo"
                      secondary={getCar(trips.data, occurrence.trip_id)}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText secondary={occurrence.description} />
                  </ListItem>
                  <Button
                    variant="contained"
                    onClick={() => goToDetails(occurrence.trip_id)}
                    fullWidth={true}
                    style={{
                      marginTop: ".6rem",
                      borderRadius: "50px",
                      height: "2rem",
                      background:
                        "linear-gradient(to right, #f98c2e, #fa3675, #ce37d8)",
                    }}
                  >
                    Detalhes
                  </Button>
                </div>
              )
            );
          })}
        </List>
      </Paper>
      <br />
      <Button
        variant="contained"
        onClick={() => navigate("/")}
        fullWidth={true}
      >
        Voltar
      </Button>
    </div>
  );
};

export default Occurrences;

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: ${({ expanded }) => (expanded ? "fit-content" : "5.5rem")};
  min-height: ${({ expanded }) => (expanded ? "10rem" : "5.5rem")};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ today, status }) =>
    today &&
    status === "pending" &&
    `background-color: #6c5dd3; box-shadow:0rem 0.4rem 0rem #cfc8fd; `}

  ${({ today, status }) =>
    today &&
    status !== "pending" &&
    `background-color: #F57A97; box-shadow:0rem 0.4rem 0rem #fcb8c8; `}
${({ today, status }) =>
    today &&
    status !== "pending" &&
    status !== "waiting" &&
    `background-color: #6BD58B; box-shadow:0rem 0.4rem 0rem #B8FFCD; `}
    ${({ today, status }) =>
    today &&
    status !== "pending" &&
    status !== "waiting" &&
    status !== "approved" &&
    `background-color: #7a7a7a; box-shadow:0rem 0.4rem 0rem #b5b5b5; `}
  border-bottom: ${({ today }) => !today && "1px solid rgba(0, 0, 0, .2)"};
  border-radius: ${({ today }) => today && "0.8rem"};
  box-sizing: border-box;
  padding-top: 0.6rem;
  margin-top: 1.2rem;
  overflow: hidden;
  transition: all 0.3s;
  border-radius: 1rem;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${({ justify }) => justify || "center"};
  align-items: ${({ align }) => align || "center"};
  box-sizing: border-box;
  border-radius: 1rem;
`;

export const Col = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => justify || "center"};
  align-items: ${({ align }) => align || "center"};
  box-sizing: border-box;
  border-radius: 1rem;
`;

export const Date = styled.div`
  width: 6rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;

  span {
    font-size: 0.6rem;
  }

  h2 {
    font-size: 1.6rem;
  }
`;

export const DateWrapper = styled.div`
  width: 4.1rem;

  ${({ today, color }) =>
    !today &&
    `background-color: ${color.primary}; color: #403C3C;   box-shadow: 0rem 0.3rem 0rem ${color.secondary};`}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border-radius: 1rem;
  .dateDay {
    font-size: 1.4rem;
    font-weight: bold;
  }
`;

export const CardHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ today }) => (today ? "#fff" : "#363636")};
  padding-right: 3.6rem;
  span {
    font-size: 0.66rem;
  }
  h2 {
    font-size: 1rem;
    font-stretch: ultra-condensed;
  }
  border-radius: 1rem;
`;

export const CardBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
`;

export const Status = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 0.2rem 3rem;
  padding-left: 0rem;
  margin-top: 0.12rem;

  span {
    font-size: 0.7rem;

    color: ${({ status }) =>
      status === "pending"
        ? "#FFE2AC"
        : status === "approved"
        ? "#fff"
        : status === "canceled"
        ? "#FF85A2"
        : status === "finished"
        ? "#6ac476"
        : status === "waiting"
        ? "#FFF"
        : "#ffc107"};
  }
`;

export const Hour = styled.div`
  width: fit-content;
  height: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fffcb5;
  padding: 0.1rem 0.5rem;
  border-radius: 0.4rem;

  p {
    font-size: 0.7rem;
  }
`;

export const Icon = styled.div`
  width: 0.5rem;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Description = styled.div`
  width: 100%;
  color: ${({ today }) => (today ? "#fff" : "#363636")};
  font-size: 0.78rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.1rem 2.15rem;
  text-align: justify;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

export const Button = styled.button`
  width: 100%;
  height: 3rem;
  font-size: 0.8rem;
  background-color: ${({ today }) => (!today ? "#fff" : "rgba(0,0,0,0)")};
  color: ${({ today }) => (today ? "#fff" : "#000")};
  border: none;
  &:active {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-top: 0.05rem solid rgba(255, 255, 255, 0.2);
  border-radius: 1rem;
`;

export const CardFooter = styled.div`
  width: 100%;
  opacity: ${({ expanded }) => (expanded ? "1" : "0")};
  border-top: 0.05rem solid rgba(255, 255, 255, 0.2);
  border-radius: 1rem;
`;

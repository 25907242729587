import FadeIn from "react-fade-in/lib/FadeIn";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { Wrapper, Container, PageHeader, Subtitle, Title } from "./styles";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetCars } from "../../../store/modules/cars/actions";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import axios from "axios";

import { useNavigate } from "react-router-dom";

const ListUsers = () => {
  const [code, setCode] = useState(3);
  const [users, setUsers] = useState([]);
  const [mode, setMode] = useState("edit");
  const { REACT_APP_BASE_URL } = process.env;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function loadUsers() {
      dispatch(resetCars());
      const { data } = await axios.get(`${REACT_APP_BASE_URL}/users`);

      setUsers(data);
    }

    loadUsers();
  }, []);

  const filterUser = (user) => {
    if (code === 4 && user.username.split("-")[0] === "1") {
      return true;
    }
    if (code === 5 && user.username.split("-")[0] === "2") {
      return true;
    }
    if (code === 1 && user.admin === true) {
      return true;
    }
    if (code === 2 && user.mt === true) {
      return true;
    }
    if (code === 3 && user.admin === false && user.mt === false) {
      return true;
    }
    if (code === 7) {
      return true;
    }
  };

  const handleDelete = async (id) => {
    const prompt = window.confirm("Deseja realmente excluir este usuário?");
    if (prompt) {
      const response = await axios.delete(`${REACT_APP_BASE_URL}/user/${id}`);
      if (response.data.id) {
        setUsers(users.filter((user) => user.id !== id));
        alert("Usuário excluído com sucesso!");
        setMode("edit");
      }
    }
  };

  return (
    <Container>
      <Wrapper>
        <FadeIn>
          <div
            style={{
              padding: "2rem",
            }}
          >
            <PageHeader>
              <Title>Usuários</Title>
              <Subtitle>Listagem de usuários</Subtitle>
            </PageHeader>
            <br />
            <FormControl fullWidth={true}>
              <InputLabel id="demo-simple-select-label">Filtrar por</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={code}
                label="Filtrar por"
                placeholder="Filtrar por"
                onChange={(e) => setCode(e.target.value)}
                size="small"
                fullWidth={true}
              >
                <MenuItem value={1}>Administrador</MenuItem>
                <MenuItem value={2}>Motoristas</MenuItem>
                <MenuItem value={3}>Comuns</MenuItem>
                <MenuItem value={4}>TV Cabo Branco</MenuItem>
                <MenuItem value={5}>TV Paraíba</MenuItem>
                {/* <MenuItem value={6}>Nome do usuário</MenuItem> */}
                {/* <MenuItem value={7}>Matrícula</MenuItem> */}
                <MenuItem value={7}>Todos</MenuItem>
              </Select>

              <Paper
                style={{
                  height: "calc(100vh - 300px)",
                  overflowY: "auto",
                  overflowX: "hidden",
                  borderBottom: "transparent",
                  boxShadow: "none",
                }}
              >
                <List>
                  {users.map((user) => {
                    return (
                      filterUser(user) && (
                        <ListItem key={user.id}>
                          <ListItemText
                            primary="Nome"
                            secondary={`${user.firstname} ${user.lastname}`}
                          />
                          &nbsp;&nbsp;&nbsp;
                          <ListItemText
                            primary="Empresa"
                            secondary={
                              user.username.split("-")[0] === "1"
                                ? "TV Cabo Branco"
                                : "TV Paraíba - CG"
                            }
                          />
                          &nbsp;
                          {mode === "edit" && (
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={() => {
                                navigate(`/user/edit`, { state: { user } });
                              }}
                            >
                              Editar
                            </Button>
                          )}
                          {mode === "delete" && (
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => {
                                handleDelete(user.id);
                              }}
                            >
                              Deletar
                            </Button>
                          )}
                        </ListItem>
                      )
                    );
                  })}
                </List>
              </Paper>
            </FormControl>

            <Button
              variant="contained"
              onClick={() => navigate("/user/create")}
              fullWidth
            >
              Adicionar
            </Button>

            <Button
              variant="contained"
              onClick={() => navigate("/")}
              fullWidth
              color="error"
              style={{ marginTop: "10px" }}
            >
              Cancelar
            </Button>

            <Button
              variant="contained"
              onClick={() => {
                if (mode === "edit") {
                  alert(
                    "Esta ação irá habilitar a exclusão de usuários, para cancelar, clique no botão novamente"
                  );
                }
                setMode(mode === "edit" ? "delete" : "edit");
              }}
              style={{ marginTop: "10px" }}
              color="inherit"
              fullWidth
            >
              {mode === "edit" ? "Deletar" : "Editar"}
            </Button>
          </div>
        </FadeIn>
      </Wrapper>
    </Container>
  );
};

export default ListUsers;

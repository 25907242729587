import axios from "axios";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Switch } from "@mui/material";
import FadeIn from "react-fade-in/lib/FadeIn";
import { useNavigate, useLocation } from "react-router-dom";

import { Wrapper, Container, PageHeader, Subtitle, Title } from "./styles";
import { useState } from "react";

const EditCar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { car } = location.state;

  const [code, setCode] = useState(car.code);

  const [model, setModel] = useState(car.model);
  const [plate, setPlate] = useState(car.plate);

  const { REACT_APP_BASE_URL } = process.env;

  async function handleConfirm() {
    const newCar = {
      model,
      plate,
      code,
    };

    const response = await axios.put(
      `${REACT_APP_BASE_URL}/cars/${car.id}`,
      newCar
    );

    if (response.status === 201) {
      alert("Veículo cadastrado com sucesso!");
      navigate("/");
    }
  }

  return (
    <Container>
      <Wrapper>
        <FadeIn>
          <PageHeader>
            <br />
            <Title>Inspecionar veículo</Title>
            <br />
            <Subtitle>
              Edite as informações do veículo e confirme para salvar
            </Subtitle>
          </PageHeader>
          <br />
          <br />

          <FormControl fullWidth>
            <div className="col">
              <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={code}
                label="Empresa"
                placeholder="Empresa"
                onChange={(e) => setCode(e.target.value)}
                size="small"
                fullWidth
              >
                <MenuItem value={1}>TV Cabo Branco - JP</MenuItem>
                <MenuItem value={2}>TV Paraíba - CG</MenuItem>
              </Select>
              &nbsp;&nbsp;
            </div>

            <br />
            <TextField
              label="Modelo do veículo"
              fullWidth={true}
              onChange={(e) => setModel(e.target.value)}
              autoComplete="off"
              size="small"
              focused={true}
              value={model}
            />
            <br />
            <TextField
              label="Placa do veículo"
              fullWidth={true}
              onChange={(e) => setPlate(e.target.value)}
              autoComplete="off"
              size="small"
              focused={true}
              value={plate}
            />
            <br />
            {/* 
            <div>
              Gasolina
              <Switch />
              Pintura
              <Switch />
            </div> */}
          </FormControl>
          <br />
          <Button
            variant="contained"
            onClick={() => handleConfirm()}
            fullWidth={true}
            disabled={!model || !plate || model.length < 3 || plate.length < 3}
          >
            Confirmar
          </Button>
          <br />
          <Button
            variant="contained"
            onClick={() => navigate("/car/list")}
            fullWidth={true}
            color="error"
          >
            Cancelar
          </Button>
        </FadeIn>
      </Wrapper>
    </Container>
  );
};

export default EditCar;

import { FaPowerOff } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateContext } from "../../../store/modules/context/actions";
import { resetTrips } from "../../../store/modules/trips/actions";
import { resetCars } from "../../../store/modules/cars/actions";

import Button from "@mui/material/Button";

const AdminSideNav = (props) => {
  const dispatch = useDispatch();
  function logout() {
    localStorage.removeItem("token");
    dispatch(
      updateContext("user", {
        id: "",
      })
    );
    dispatch(updateContext("sidenav", false));
    dispatch(resetTrips());
    dispatch(resetCars());
  }

  return (
    <Link to="/login" onClick={() => logout()}>
      <Button variant="text" fullWidth={true} className="btn">
        <FaPowerOff size={12} color="#000" className="icon" /> &nbsp;{" "}
        <span>Sair</span>
      </Button>
    </Link>
  );
};

export default AdminSideNav;

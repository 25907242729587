import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import cidades from "../../../utils/cidades.json";

export default function Highlights(props) {
  return (
    <Autocomplete
      id="highlights-demo"
      sx={{ width: "100%" }}
      options={cidades}
      getOptionLabel={(option) => option.nome}
      onChange={(event, value) => {
        props.onChange(value.nome);
      }}
      renderInput={(params) => (
        <TextField {...params} label={props.label} margin="normal" />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.nome, inputValue);
        const parts = parse(option.nome, matches);

        return (
          <li {...props}>
            <div>
              {parts.map((part, index) => (
                <span
                  onClick={props.onChange}
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
    />
  );
}

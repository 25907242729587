const initialState = {
  data: [
    // {
    //   id: 1,
    //   model: "COBALT",
    //   plate: "ABC-1234",
    //   code: 1,
    //   km: 0,
    //   gas: 0,
    //   gnv: 0,
    //   paint: true,
    //   air: true,
    //   tireiron: true,
    //   clean: true,
    //   jack: true,
    //   oil: true,
    //   tires: true,
    //   hubcaps: true,
    //   breaks: true,
    //   alerts: true,
    //   seat: true,
    //   lights: true,
    //   backlights: true,
    //   water: true,
    //   triangles: true,
    // },
  ],
};

const carsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "@cars/ADD":
      return {
        ...state,
        data: [action.payload.car, ...state.data],
      };
    case "@cars/DELETE":
      return {
        ...state,
        data: state.data.filter((car) => car.id !== action.payload.id),
      };
    case "@cars/RESET":
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
};

export default carsReducer;

import axios from "axios";
import { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Checkbox } from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";

import { Wrapper, Container, PageHeader, Subtitle, Title } from "./styles";

const TripDetails = (props) => {
  const navigate = useNavigate();

  const { REACT_APP_BASE_URL } = process.env;

  const { state } = useLocation();

  const { data, from } = state;

  const [occurrences, setOccurences] = useState([]);
  const [checked, setChecked] = useState(false);

  const formattedHour = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHour = `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }`;
    return formattedHour;
  };

  const checkOccurrence = async (occurrence) => {
    let description = occurrence.description;

    await axios.put(`${REACT_APP_BASE_URL}/occurrences/${occurrence.id}`, {
      description: description.includes("[[OK]]")
        ? description.replace("[[OK]]", "")
        : `${description} [[OK]]`,
    });
    loadOccurrences();
  };

  const loadOccurrences = async (occurrences) => {
    const response = await axios.get(
      `${REACT_APP_BASE_URL}/occurrences/${data.id}`
    );
    setOccurences(response.data.occurrences);
  };

  useEffect(() => {
    loadOccurrences(occurrences);
  }, []);

  return (
    <Container>
      <Wrapper>
        <br />
        <PageHeader>
          <Title>Detalhes da viagem</Title>
          <Subtitle>Detalhes da viagem</Subtitle>
        </PageHeader>

        <FormControl fullWidth>
          <Paper
            style={{
              width: "100%",
              height: "calc(100vh - 300px)",
              overflowY: "auto",
              overflowX: "hidden",
              borderBottom: "transparent",
              boxShadow: "none",
            }}
          >
            <List>
              <ListItem>
                <ListItemText
                  primary="Data inicial"
                  secondary={new Date(data.start_date).toLocaleDateString(
                    "pt-BR"
                  )}
                />
                &nbsp;&nbsp;&nbsp;
                <ListItemText
                  primary="Hora"
                  secondary={formattedHour(data.createdAt)}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Data final"
                  secondary={
                    data.end_date
                      ? new Date(data.end_date).toLocaleDateString("pt-BR")
                      : `Não definido  `
                  }
                />
                &nbsp;&nbsp;&nbsp;
                <ListItemText
                  primary="Hora"
                  secondary={
                    data.status === "finished"
                      ? formattedHour(data.updatedAt)
                      : "xx:xx"
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Destino"
                  secondary={`${data.from} - ${data.to}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Observação:"
                  secondary={data.description}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Veículo" secondary={data.car} />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Km inicial"
                  secondary={data.initial_km}
                />
                &nbsp;&nbsp;&nbsp;
                <ListItemText primary="Km final" secondary={data.final_km} />
              </ListItem>
              {occurrences.map((occurrence) => (
                <ListItem key={occurrence.id}>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <ListItemText
                      primary="Ocorrência"
                      secondary={occurrence.description}
                    />
                    <Button
                      onClick={() => checkOccurrence(occurrence)}
                      fullWidth
                    >
                      {occurrence.description.includes("[[OK]]")
                        ? "Marcar como não OK"
                        : "Marcar como OK"}
                    </Button>
                  </div>
                </ListItem>
              ))}
            </List>
          </Paper>
        </FormControl>
      </Wrapper>
      <br />
      <Button
        variant="contained"
        onClick={() => {
          if (from === "occurrences") {
            navigate("/occurrences");
          } else {
            navigate("/");
          }
        }}
        fullWidth
      >
        OK
      </Button>
    </Container>
  );
};

export default TripDetails;

import styled from "styled-components";

import logoimg from "../../../assets/img/logo.png";

export const Container = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  align-items: center;
  position: absolute;
`;

export const Button = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:active {
    background-color: #f8f8f8;
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.div`
  width: 1.8rem;
  height: 1.8rem;
  background-image: url(${logoimg});
  background-size: contain;
  background-repeat: no-repeat;
`;

const initialState = {
  data: [
    {
      id: 1,
      name: "John Doe",
      username: "833-1",
      admin: false,
      mt: false,
    },
  ],
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "@user/ADD":
      return {
        ...state,
        data: [action.payload.car, ...state.data],
      };
    case "@user/DELETE":
      return {
        ...state,
        data: state.data.filter((car) => car.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export default usersReducer;

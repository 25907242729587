import axios from "axios";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FadeIn from "react-fade-in/lib/FadeIn";
import { useNavigate } from "react-router-dom";

import { Wrapper, Container, PageHeader, Subtitle, Title } from "./styles";
import { useState } from "react";

const CreateUser = (props) => {
  const [code, setCode] = useState(1);
  const [username, setUsername] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [admin, setAdmin] = useState(false);
  const [mt, setMt] = useState(false);

  const { REACT_APP_BASE_URL } = process.env;

  const navigate = useNavigate();

  function onBlur() {
    if (username.length === 2) {
      setUsername(`0${username}`);
    } else if (username.length === 1) {
      setUsername(`00${username}`);
    } else {
      return;
    }
  }

  async function handleConfirm() {
    const newUser = {
      username: `${code}-${username}`,
      firstname,
      lastname,
      admin,
      mt,
    };

    const response = await axios.post(`${REACT_APP_BASE_URL}/signup`, newUser);

    if (response.status === 201) {
      alert("Usuário criado com sucesso!");
      navigate("/");
    }
  }

  return (
    <Container>
      <Wrapper>
        <FadeIn>
          <PageHeader>
            <br />
            <Title>Criar usuário</Title>
            <br />
            <Subtitle>
              Para criar um usuário, insira as informações abaixo:
            </Subtitle>
          </PageHeader>
          <br />
          <br />

          <FormControl fullWidth>
            <div className="col">
              <InputLabel id="demo-simple-select-label">Cód.</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={code}
                label="cod"
                placeholder="cod"
                onChange={(e) => setCode(e.target.value)}
                size="small"
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
              </Select>
              &nbsp;&nbsp;
              <TextField
                label="Matrícula"
                fullWidth={true}
                type="number"
                onChange={(e) => setUsername(e.target.value)}
                autoComplete="off"
                size="small"
                inputMode="numeric"
                onBlur={() => onBlur()}
                value={username}
              />
              &nbsp;&nbsp;
              <FormControl fullWidth={true}>
                <InputLabel id="demo-simple-select-label2">Admin?</InputLabel>
                <Select
                  id="demo-simple-select"
                  labelId="demo-simple-select-label2"
                  value={admin}
                  label="Admin?"
                  placeholder="cod"
                  onChange={(e) => setAdmin(e.target.value)}
                  size="small"
                >
                  <MenuItem value={true}>Sim</MenuItem>
                  <MenuItem value={false}>Não</MenuItem>
                </Select>
              </FormControl>
            </div>
            <br />
            <FormControl fullWidth={true}>
              <InputLabel id="demo-simple-select-label3">
                Tipo de conta
              </InputLabel>
              <Select
                id="demo-simple-select"
                labelId="demo-simple-select-label3"
                value={mt}
                label="Tipo de conta"
                placeholder="cod"
                onChange={(e) => setMt(e.target.value)}
                size="small"
              >
                <MenuItem value={false}>Comum</MenuItem>
                <MenuItem value={true}>Motorista</MenuItem>
              </Select>
            </FormControl>
            <br />
            <TextField
              label="Primeiro nome"
              fullWidth={true}
              onChange={(e) => setFirstname(e.target.value)}
              autoComplete="off"
              size="small"
            />
            <br />
            <TextField
              label="Segundo nome"
              fullWidth={true}
              onChange={(e) => setLastname(e.target.value)}
              autoComplete="off"
              size="small"
            />
            <br />
          </FormControl>

          <Button
            variant="contained"
            onClick={() => handleConfirm()}
            fullWidth={true}
            disabled=""
          >
            Finalizar
          </Button>
          <br />
          <Button
            variant="contained"
            onClick={() => navigate("/user/list")}
            fullWidth={true}
            disabled=""
            color="error"
          >
            Cancelar
          </Button>
        </FadeIn>
      </Wrapper>
    </Container>
  );
};

export default CreateUser;

const initialState = {
  data: [
    // {
    //   id: generateId(),
    //   driver: "José da Silva",
    //   car: "Cobalt - NQK-0991",
    //   description:
    //     "Reunião com o gerente da empresa para discutir o andamento do projeto.",
    //   from: "João Pessoa",
    //   to: "Campina Grande",
    //   initial_km: "",
    //   final_km: "",
    //   refuelling: [],
    //   occurrences: [],
    //   start_date: "2022-06-03",
    //   end_date: "2022-06-05",
    //   status: "waiting",
    // },
  ],
};

const tripsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "@trips/CREATE":
      return {
        ...state,
        data: [action.payload.trip, ...state.data],
      };
    case "@trips/UPDATE":
      return {
        ...state,
        data: state.data.map((trip) =>
          trip.id === action.payload.id
            ? { ...trip, ...action.payload.values }
            : trip
        ),
      };
    case "@trips/DELETE":
      return {
        ...state,
        data: state.data.filter((trip) => trip.id !== action.payload.id),
      };
    case "@trips/RESET":
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
};

export default tripsReducer;

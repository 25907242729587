import { Container } from "./styles";

import { GoPlus } from "react-icons/go";

const Fab = ({onClick}) => {
  return (
    <Container onClick={onClick}>
      <GoPlus color="#fff"/>
    </Container>
  );
};

export default Fab;

export const createTrip = (trip) => ({
  type: "@trips/CREATE",
  payload: {
    trip,
  },
});

export const deleteTrip = (id) => ({
  type: "@trips/DELETE",
  payload: {
    id,
  },
});

export const updateTrip = (id, values) => ({
  type: "@trips/UPDATE",
  payload: {
    id,
    values,
  },
});

export const resetTrips = () => ({
  type: "@trips/RESET",
});

import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateContext } from "../../../store/modules/context/actions";
import { Wrapper } from "./styles";

import Sidebar from "react-sidebar";
import AdminSideNav from "./admin";
import UserSideNav from "./user";

const SideNav = () => {
  const context = useSelector((state) => state.context);
  const dispatch = useDispatch();

  const wrapperRef = useRef();

  function onSetSidebarOpen(open) {
    dispatch(updateContext("sidenav", false));
  }

  const SideNavToRender = context.user.admin ? AdminSideNav : UserSideNav;

  return (
    <Wrapper ref={wrapperRef}>
      <Sidebar
        sidebar={<SideNavToRender />}
        sidebarClassName="sidebar"
        open={context.sidenav}
        onSetOpen={onSetSidebarOpen}
        styles={{ sidebar: { background: "white" } }}
        transitions={true}
        touch={true}
        defaultSidebarWidth={150}
      ></Sidebar>
    </Wrapper>
  );
};

export default SideNav;

const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
}

export function formatDate(date) {
  return new Date(date).toLocaleDateString("pt-BR", options);
}

export function generateDate(){

}

export function isToday(date){
  return date === new Date().toLocaleDateString('en-GB').split('/').reverse().join('-')
} 
import { createStore } from "redux";
import { combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import trips from "./modules/trips/reducer";
import context from "./modules/context/reducer";
import cars from "./modules/cars/reducer";
import users from "./modules/users/reducer";

const rootReducer = combineReducers({
  trips,
  context,
  cars,
  users,
});

const store = createStore(
  rootReducer,
  //compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__())
);

export default store;

export const dateColors = [
    {
        primary: "#CFC8FD",
        secondary: "#E7E3FE",
    },
    {
        primary: "#A0D7E7",
        secondary: "#CFEBF3",
    },
    {
        primary: "#FFE2AC",
        secondary: "#FFF0D5",
    },
    {
        primary: "#F4A1C0",
        secondary: "#FCECF2",
    },
];

import axios from "axios";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";

import { Wrapper, Container } from "./styles";

import SideNav from "./widgets/sidenav";
import Header from "./widgets/header";
import Home from "./views/home";
import Schedule from "./views/schedule";
import Checkin from "./views/checkin";
import Refuel from "./views/refuel";
import Checkout from "./views/checkout";
import Approve from "./views/approve";
import TripDetails from "./views/trip-details";
import Login from "./views/login";

import { createTrip } from "../store/modules/trips/actions";
import { addCar, resetCars } from "../store/modules/cars/actions";
import CreateUser from "./views/create-user";
import CreateCar from "./views/create-car";
import ListCars from "./views/ListCars";
import EditCar from "./views/edit-car";
import Occurrences from "./views/occurrences";
import Checklist from "./views/checklist";
import ListUsers from "./views/list-users";
import EditUser from "./views/edit-user";

const AppMobile = () => {
  const { context, cars: carros } = useSelector((state) => state);

  const { REACT_APP_BASE_URL } = process.env;
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      if (context.user.id && context.user?.admin) {
        const trips = await axios.get(`${REACT_APP_BASE_URL}/trips`);

        dispatch(resetCars());

        const cars = await axios.get(`${REACT_APP_BASE_URL}/cars`);

        trips.data.map(async (trip, index) => {
          const { data: car } = trip.car_id
            ? await axios.get(`${REACT_APP_BASE_URL}/cars/${trip.car_id}`)
            : { data: { id: null, name: null, plate: null } };

          const { data: drivers } = await axios.get(
            `${REACT_APP_BASE_URL}/users`
          );

          const driver = drivers.filter((driver) => driver.id === trip.user_id);

          dispatch(
            createTrip({
              driver: `${driver[0].firstname} ${driver[0].lastname}`,
              car: car && `${car.model} - ${car.plate}`,
              ...trip,
            })
          );
        });

        if (!carros.data) {
          cars.data.map(async (car) => {
            dispatch(addCar(car));
          });
        } else {
          cars.data.map(async (car) => {
            if (!carros.data.find((c) => c.id === car.id)) {
              dispatch(addCar(car));
            }
          });
        }
      }

      if (context.user.id && !context.user?.admin) {
        const trips = await axios.get(
          `${REACT_APP_BASE_URL}/trip/${context.user.id}`
        );

        dispatch(resetCars());

        const cars = await axios.get(`${REACT_APP_BASE_URL}/cars`);

        trips &&
          trips.data.map(async (trip) => {
            const { data: car } = trip.car_id
              ? await axios.get(`${REACT_APP_BASE_URL}/cars/${trip.car_id}`)
              : { data: { id: null, name: null, plate: null } };
            dispatch(
              createTrip({
                car: car && `${car.model} - ${car.plate}`,
                ...trip,
              })
            );
          });

        if (!carros.data) {
          cars.data.map(async (car) => {
            dispatch(addCar(car));
          });
        } else {
          cars.data.map(async (car) => {
            if (!carros.data.find((c) => c.id === car.id)) {
              dispatch(addCar(car));
            }
          });
        }
      }
    }
    fetchData();
  }, [context.user.id]);

  return (
    <Wrapper>
      {context.user.id && <Header onClickMenu={() => {}} />}
      {context.sidenav && <SideNav />}
      <Container>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/car/list" element={<ListCars />} />
          <Route path="/car/create" element={<CreateCar />} />
          <Route path="/car/edit" element={<EditCar />} />
          <Route path="/details" element={<TripDetails />} />
          <Route path="/user/create" element={<CreateUser />} />
          <Route path="/user/list" element={<ListUsers />} />
          <Route path="/user/edit" element={<EditUser />} />
          <Route path="/root" element={<AppMobile />} />
          <Route path="/login" element={<Login />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/checkin" element={<Checkin />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/refuel" element={<Refuel />} />
          <Route path="/approve" element={<Approve />} />
          <Route path="/occurrences" element={<Occurrences />} />
          <Route path="/checklist" element={<Checklist />} />
        </Routes>
      </Container>
    </Wrapper>
  );
};

export default AppMobile;

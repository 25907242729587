import { useState } from "react";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { useNavigate } from "react-router-dom";

import { Container, Logo, MarcasWrapper, Marcas } from "./styles";

const Login = (props) => {
  const [username, setUsername] = useState("");

  const [code, setCode] = useState(1);

  const navigate = useNavigate();

  async function handleSubmit() {
    const { REACT_APP_BASE_URL } = process.env;
    const { data } = await axios
      .post(`${REACT_APP_BASE_URL}/signin`, {
        username: `${code}-${username}`,
      })
      .catch((err) => {
        if (err) {
          alert("Matrícula inválida");
        }
      });

    if (data.token) {
      localStorage.setItem("token", data.token);
      navigate("/");
    }
  }

  return (
    <>
      <Container>
        <div className="wrapper">
          <Logo />
          <h4>Frota Cabo Branco</h4>
          <br />
          <div className="input-row">
            <FormControl>
              <InputLabel id="demo-simple-select-label">Cód.</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={code}
                label="cod"
                placeholder="cod"
                onChange={(e) => setCode(e.target.value)}
                size="small"
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
              </Select>
            </FormControl>
            &nbsp;&nbsp;
            <FormControl>
              <TextField
                label="Matrícula"
                onChange={(e) => setUsername(e.target.value)}
                autoComplete="off"
                inputProps={{ maxLength: 4 }}
                size="small"
                type="number"
                className="input-field"
              />
            </FormControl>
          </div>
          <br />
          <Button
            variant="contained"
            onClick={() => handleSubmit()}
            fullWidth={true}
            className="loginbtn"
            disabled={!username}
          >
            Login
          </Button>
        </div>
      </Container>
      <MarcasWrapper>
        <Marcas />
      </MarcasWrapper>
    </>
  );
};

export default Login;

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 1rem;
`;

export const ItemTitle = styled.span`
  font-size: 1rem;
  font-weight: bold;
`;

export const Title = styled.span`
  font-family: roboto;
  font-size: 1.5rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.9);
`;

export const SubTitle = styled.span`
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 0.3rem;
  span {
    font-weight: 600;
  }
`;

import axios from "axios";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  GoLocation,
  GoClock,
  GoCheck,
  GoAlert,
  GoTriangleDown,
  GoTriangleUp,
} from "react-icons/go";
import {
  Container,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  Date as CardDate,
  Status,
  Description,
  ButtonContainer,
  Button,
  DateWrapper,
} from "./styles";

import { formatDate } from "../../../utils/dateUtils";
import { toTitleCase } from "../../../utils/stringUtils";

import { deleteTrip, updateTrip } from "../../../store/modules/trips/actions";
import { updateContext } from "../../../store/modules/context/actions";
import { useNavigate } from "react-router-dom";
import { Column } from "react-rainbow-components";

const Card = ({ data, color, admin, closed }) => {
  const { REACT_APP_BASE_URL } = process.env;

  const dispatch = useDispatch();
  const { context } = useSelector((state) => state);

  const navigate = useNavigate();

  const today =
    data.status === "waiting" ||
    data.status === "pending" ||
    data.status === "rejected" ||
    data.status === "approved";

  const [expanded, setExpanded] = useState(!closed && today ? true : false);

  const formattedDate = toTitleCase(formatDate(data.start_date));

  async function cancelTrip(id) {
    // eslint-disable-next-line no-restricted-globals
    const prompt = confirm(
      "Esta ação irá 𝗰𝗮𝗻𝗰𝗲𝗹𝗮𝗿 𝗮 𝘃𝗶𝗮𝗴𝗲𝗺. Deseja continuar?"
    );
    if (prompt) {
      dispatch(updateTrip(data.id, { status: "canceled" }));
      alert("Viagem cancelada com sucesso!");
      await axios.put(`${REACT_APP_BASE_URL}/trip/${data.id}`, {
        status: "canceled",
      });
    }
  }

  function goToCheckin() {
    // eslint-disable-next-line no-restricted-globals
    const prompt = confirm(
      "𝗔𝘁𝗲𝗻𝗰̧𝗮̃𝗼:\nPara iniciar o checkin, você precisa estar dentro do veículo para fornecer algumas informações. Deseja continuar?"
    );
    if (prompt) {
      dispatch(updateContext("currentCard", data.id));
      navigate("/checkin");
    }
  }

  function goToCheckout() {
    // eslint-disable-next-line no-restricted-globals
    const prompt = confirm(
      "𝗔𝘁𝗲𝗻𝗰̧𝗮̃𝗼: A ação a seguir irá finalizar a viagem. Deseja continuar?"
    );
    if (prompt) {
      dispatch(updateContext("currentCard", data.id));
      navigate("/checkout");
    }
  }

  function refuel() {
    // eslint-disable-next-line no-restricted-globals
    const prompt = confirm(
      "Esta ação irá direcioná-lo para registro de abastecimento. Deseja continuar?"
    );
    if (prompt) {
      dispatch(updateContext("currentCard", data.id));
      navigate("/refuel");
    }
  }

  function approve() {
    // eslint-disable-next-line no-restricted-globals
    const prompt = confirm("Deseja aprovar esta solicitação?");
    if (prompt) {
      dispatch(updateContext("currentCard", data.id));
      navigate("/approve");
    }
  }

  async function removeTrip() {
    // eslint-disable-next-line no-restricted-globals
    const prompt = confirm("Deseja excluir esta viagem?");
    if (prompt) {
      await axios.delete(`${REACT_APP_BASE_URL}/trip/${data.id}`);
      dispatch(deleteTrip(data.id));
      alert("Viagem excluída com sucesso!");
      navigate("/");
    }
  }

  return (
    <Container expanded={expanded} today={today} status={data.status}>
      <Row onClick={() => setExpanded(!expanded)}>
        <CardDate>
          <DateWrapper today={today} color={color} status={data.status}>
            <span>{formattedDate.substring(0, 3)}</span>
            <span className="dateDay">
              {new Date(data.start_date).getDate()}
            </span>
            <span>{formattedDate.split(" ")[3]}</span>
          </DateWrapper>
        </CardDate>

        <Col>
          <CardHeader today={today}>
            {!context.user.admin && data.status === "waiting" && (
              <h2>Em análise</h2>
            )}

            {context.user.admin && <h2>{data.driver}</h2>}

            {!context.user.admin &&
              (data.status === "pending" ||
                data.status === "approved" ||
                data.status === "finished") && <h2>{data.car}</h2>}

            {!context.user.admin && data.status === "rejected" && (
              <h2>Não aprovada</h2>
            )}
            {!context.user.admin && data.status === "canceled" && (
              <h2>Cancelada</h2>
            )}

            {!context.user.admin && (
              <span>
                <GoLocation color={today ? "#fff" : "#363636"} size={10} />
                &nbsp;{data.from} - {data.to}
              </span>
            )}

            {context.user.admin && data.status === "waiting" && (
              <span>
                <GoLocation color={today ? "#fff" : "#363636"} size={10} />
                &nbsp;{data.from} - {data.to}
              </span>
            )}
            {context.user.admin && data.status !== "waiting" && (
              <span>{data.car}</span>
            )}
          </CardHeader>
          <CardBody>
            <Status status={data.status}>
              {/* {data.status !== "analise" && (
                <Hour>
                  <p>{data.hour}</p>
                </Hour>
              )} */}
              {data.status === "pending" ? (
                <span>
                  Aguard. Check-in&nbsp;
                  <GoClock size={8} />
                </span>
              ) : data.status === "approved" ? (
                <span>
                  Em andamento&nbsp;
                  <GoClock size={8} />
                </span>
              ) : data.status === "canceled" ? (
                <span>
                  Cancelado&nbsp;
                  <GoAlert size={8} />
                </span>
              ) : data.status === "finished" ? (
                <span>
                  Finalizado&nbsp;
                  <GoCheck size={8} />
                </span>
              ) : data.status === "waiting" ? (
                <span>
                  Aguard. aprovação&nbsp;
                  <GoClock size={8} />
                </span>
              ) : data.status === "rejected" ? (
                <span>
                  Solicitação rejeitada&nbsp;
                  <GoClock size={8} />
                </span>
              ) : (
                <span>
                  Pendente&nbsp;
                  <GoClock size={8} />
                </span>
              )}
            </Status>

            <Row justify="flex-end">
              {expanded ? (
                <GoTriangleUp
                  size={9.5}
                  color={today ? "#fff" : "#363636"}
                  style={{
                    marginRight: "1rem",
                    marginTop: "-1rem",
                  }}
                />
              ) : (
                <GoTriangleDown
                  size={9.5}
                  color={today ? "#fff" : "#363636"}
                  style={{
                    marginRight: "1rem",
                    marginTop: "-1rem",
                  }}
                />
              )}
            </Row>
          </CardBody>
        </Col>
      </Row>

      {context.user.admin ? (
        <>
          {data.status !== "waiting" && (
            <Status>
              <span
                style={{
                  paddingTop: "0.5rem",
                  paddingLeft: "4rem",
                  color:
                    data.status === "approved" ||
                    data.status === "waiting" ||
                    data.status === "pending"
                      ? "#fff"
                      : "#000",
                }}
              >
                <GoLocation
                  color={
                    data.status === "approved" ||
                    data.status === "waiting" ||
                    data.status === "pending"
                      ? "#fff"
                      : "#000"
                  }
                  size={10}
                />
                &nbsp;
                {data.from} - {data.to}
              </span>
            </Status>
          )}
          <Description today={today}>{data.description}</Description>
        </>
      ) : (
        <Description today={today}>
          {data.status !== "waiting"
            ? data.description
            : "Sua solicitação está em análise, aguarde a confirmação. Você ainda pode cancelar a solicitação no botão abaixo."}
        </Description>
      )}

      <CardFooter expanded={expanded}>
        {today ? (
          <ButtonContainer>
            {data.status !== "finished" && data.status !== "rejected" && (
              <Button today={today} onClick={() => cancelTrip(data.id)}>
                Cancelar
              </Button>
            )}
            {data.status === "pending" && (
              <Button today={today} onClick={() => goToCheckin()}>
                Fazer Check-in
              </Button>
            )}

            {data.status === "approved" && (
              <>
                {/* <Button today={today} onClick={() => refuel()}>
                  Abastecer
                </Button> */}

                <Button today={today} onClick={() => goToCheckout()}>
                  Finalizar
                </Button>
              </>
            )}
            {data.status === "finished" ||
              (data.status === "rejected" && (
                <Button
                  today={today}
                  onClick={() =>
                    navigate("/details", {
                      state: {
                        data: data,
                        from: "card",
                      },
                    })
                  }
                >
                  Ver detalhes
                </Button>
              ))}

            {data.status === "waiting" && admin && "rejected" && (
              <Button today={today} onClick={() => approve()}>
                Aprovar
              </Button>
            )}
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            <Button
              today={today}
              onClick={() =>
                navigate("/details", {
                  state: {
                    data: data,
                  },
                })
              }
            >
              Ver detalhes
            </Button>
            {admin && (
              <Button today={today} onClick={() => removeTrip()}>
                Excluir
              </Button>
            )}
          </ButtonContainer>
        )}
      </CardFooter>
    </Container>
  );
};

export default Card;

import {
  FaUserAlt,
  FaCarAlt,
  FaHome,
  FaPowerOff,
  FaExclamationCircle,
} from "react-icons/fa";

import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateContext } from "../../../store/modules/context/actions";
import { resetTrips } from "../../../store/modules/trips/actions";
import { resetCars } from "../../../store/modules/cars/actions";
const AdminSideNav = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  function logout() {
    localStorage.removeItem("token");
    dispatch(
      updateContext("user", {
        id: "",
      })
    );
    dispatch(updateContext("sidenav", false));
    dispatch(resetTrips());
    dispatch(resetCars());
  }

  return (
    <>
      <Button
        variant="text"
        onClick={() => {
          dispatch(updateContext("sidenav", false));
          navigate("/");
        }}
        fullWidth={true}
        className="btn"
      >
        <FaHome size={12} color="#000" className="icon" /> &nbsp;{" "}
        <span>Início</span>
      </Button>

      <Button
        variant="text"
        onClick={() => {
          dispatch(updateContext("sidenav", false));
          navigate("/user/list");
        }}
        fullWidth={true}
        className="btn"
      >
        <FaUserAlt size={12} color="#000" className="icon" /> &nbsp;{" "}
        <span>Usuários</span>
      </Button>

      <Button
        variant="text"
        onClick={() => {
          dispatch(updateContext("sidenav", false));
          navigate("/car/list");
        }}
        fullWidth={true}
        className="btn"
      >
        <FaCarAlt size={13} color="#000" className="icon" /> &nbsp;
        <span>Veículos</span>
      </Button>
      {/* <Button
        variant="text"
        onClick={() => {
          dispatch(updateContext("sidenav", false));
          navigate("/checklist");
        }}
        fullWidth={true}
        className="btn"
      >
        <FaCheck size={12} color="#000" className="icon" /> &nbsp;{" "}
        <span>Checklist</span>
      </Button> */}
      <Button
        variant="text"
        onClick={() => {
          dispatch(updateContext("sidenav", false));
          navigate("/occurrences");
        }}
        fullWidth={true}
        className="btn"
      >
        <FaExclamationCircle size={12} color="#000" className="icon" /> &nbsp;{" "}
        <span>Ocorrências</span>
      </Button>
      {/* <Button
        variant="text"
        onClick={() => {}}
        fullWidth={true}
        className="btn"
      >
        <FaGasPump size={12} color="#000" className="icon" /> &nbsp;{" "}
        <span>Abastecimento</span>
      </Button> */}
      <Link to="/login" onClick={() => logout()}>
        <Button variant="text" fullWidth={true} className="btn">
          <FaPowerOff size={12} color="#000" className="icon" /> &nbsp;{" "}
          <span>Sair</span>
        </Button>
      </Link>
    </>
  );
};

export default AdminSideNav;

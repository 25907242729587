import { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import FadeIn from "react-fade-in";

import { GrFilter } from "react-icons/gr";

import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { dateColors } from "../../../utils/colors";
import { updateContext } from "../../../store/modules/context/actions";
import { DatePicker } from "react-rainbow-components";

import {
  Container,
  PageHeader,
  Title,
  SubTitle,
  PageFooter,
  TripsWrapper,
  EmptyTrips,
} from "./styles";

import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Card from "../../widgets/card";
import Fab from "../../widgets/fab";

const Home = () => {
  const storedToken = localStorage.getItem("token");
  const [token, setToken] = useState(storedToken || undefined);

  const { trips, context } = useSelector((state) => state);
  const { user } = context;
  const colors = dateColors;

  const [filter, setFilter] = useState({
    status: "all",
    user: "",
    date: new Date(),
  });

  const [isFiltering, setIsFiltering] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const orderedTrips = [];
  const tripList = [...trips.data];
  const waitingList = tripList
    .filter((trip) => trip.status === "waiting")
    .reverse();
  const approvedList = tripList
    .filter((trip) => trip.status === "approved")
    .reverse();
  const canceledList = tripList
    .filter((trip) => trip.status === "canceled")
    .reverse();
  const finishedList = tripList
    .filter((trip) => trip.status === "finished")
    .reverse();
  const pendingList = tripList
    .filter((trip) => trip.status === "pending")
    .reverse();

  const lists = [
    waitingList,
    pendingList,
    approvedList,
    canceledList,
    finishedList,
  ];

  lists.map((list) => {
    list.map((trip) => {
      orderedTrips.push(trip);
    });
  });

  orderedTrips.sort((a, b) => {
    return new Date(b.start_date) - new Date(a.start_date);
  });

  function verifyToken(token) {
    try {
      const decoded = jwtDecode(token);

      dispatch(updateContext("user", { ...decoded }));

      if (decoded.exp * 1000 < Date.now()) {
        return false;
      }
      console.log(
        "expiracao",
        decoded.exp * 1000 - (3600000 * 23 + 36000 * 59)
      );
    } catch (error) {
      console.log(error.message);
      return false;
    }

    return true;
  }

  useEffect(() => {
    if (!token) {
      return navigate("/login");
    }

    localStorage.setItem("token", token);

    if (!verifyToken(token)) {
      localStorage.removeItem("token");
      setToken(undefined);
      return navigate("/login");
    }
  }, []);

  // function getByDate(prop, date) {
  //   return (
  //     new Date(date).getDate() === Number(prop.day) ||
  //     new Date(date).getMonth() === Number(prop.month) ||
  //     new Date(date).getFullYear() === Number(prop.year)
  //   );
  // }

  function hasApprovedTrip(trips) {
    return trips.filter((trip) => trip.status === "approved").length > 0;
  }

  function goToSchedule() {
    if (hasApprovedTrip(trips.data)) {
      return alert("Finalize o percurso atual antes de agendar um novo!");
    }
    navigate("/schedule");
  }

  return (
    <Container>
      <PageHeader>
        <SubTitle>
          Olá <span>{user.firstname}</span>,
        </SubTitle>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Title>{!context.user.admin && "Suas "}Viagens</Title>
          {context.user.admin && (
            <div onClick={() => setIsFiltering(!isFiltering)}>
              <GrFilter size={20} color="#242424" />
            </div>
          )}
        </div>
      </PageHeader>

      {user.admin ? (
        <TripsWrapper style={{ paddingTop: ".5rem" }}>
          {isFiltering && (
            <FadeIn>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Filtrar por status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filter.status}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      status: e.target.value,
                    })
                  }
                  fullWidth={true}
                  label="Filtrar por status"
                >
                  <MenuItem value="all">Todos</MenuItem>
                  <MenuItem value="waiting">Aguard. Aprovação.</MenuItem>
                  <MenuItem value="pending">Aguard. Check-in</MenuItem>
                  <MenuItem value="approved">Em andamento</MenuItem>
                  <MenuItem value="finished">Finalizado</MenuItem>
                  <MenuItem value="canceled">Cancelado</MenuItem>
                  <MenuItem value="date">Filtrar por data</MenuItem>
                </Select>
              </FormControl>

              {filter.status === "date" && (
                <>
                  <br />
                  <DatePicker
                    id="datePicker-1"
                    value={filter.date}
                    onChange={(d) => {
                      setFilter({
                        ...filter,
                        date: d.toISOString(),
                      });
                    }}
                    label="DatePicker Label"
                    formatStyle="large"
                    locale="pt-BR"
                    isCentered={true}
                    hideLabel={true}
                  />
                </>
              )}
              <FormControl
                fullWidth
                style={{
                  marginTop: ".8rem",
                }}
              ></FormControl>
              {filter.status !== "date" && (
                <TextField
                  label="Filtrar por motorista"
                  fullWidth={true}
                  type="text"
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      user: e.target.value,
                    })
                  }
                />
              )}
            </FadeIn>
          )}

          <FadeIn className="fade-in">
            {!trips.data.length && (
              <EmptyTrips>
                <span>
                  Nenhuma viagem encontrada. Recarregue a página ou entre em uma
                  conta de motorista para agendar uma viagem.
                </span>
              </EmptyTrips>
            )}

            {trips.data &&
              orderedTrips.map((trip, index) => {
                return filter.status !== "all"
                  ? trip.status === filter.status &&
                      trip.driver
                        .toLowerCase()
                        .includes(filter.user.toLowerCase()) && (
                        <Card
                          key={trip.id}
                          data={trip}
                          color={colors[index % colors.length]}
                          admin={context.user.admin}
                          closed={false}
                        />
                      )
                  : trip.driver
                      .toLowerCase()
                      .includes(filter.user.toLowerCase()) &&
                      trip.status !== "finished" &&
                      trip.status !== "canceled" && (
                        <Card
                          key={trip.id}
                          data={trip}
                          color={colors[index % colors.length]}
                          admin={context.user.admin}
                          closed={true}
                        />
                      );
              })}
            {trips.data &&
              orderedTrips.map((trip, index) => {
                return (
                  filter.status === "date" &&
                  new Date(trip.start_date)
                    .toISOString()
                    .includes(
                      new Date(filter.date).toISOString().split("T")[0]
                    ) && (
                    <Card
                      key={trip.id}
                      data={trip}
                      color={colors[index % colors.length]}
                      admin={context.user.admin}
                      closed={true}
                    />
                  )
                );
              })}
          </FadeIn>
          <PageFooter>
            <Link to="/schedule">
              <Fab />
            </Link>
          </PageFooter>
        </TripsWrapper>
      ) : (
        <>
          <TripsWrapper>
            <FadeIn>
              {!trips.data.length && (
                <EmptyTrips>
                  <span>
                    Você ainda não possui viagens cadastradas. Toque no botão
                    abaixo para começar.
                  </span>
                </EmptyTrips>
              )}
              {trips.data &&
                orderedTrips.map((trip, index) => {
                  return (
                    <Card
                      key={trip.id}
                      data={trip}
                      color={colors[index % colors.length]}
                    />
                  );
                })}
            </FadeIn>
          </TripsWrapper>

          <PageFooter>
            <Fab onClick={() => goToSchedule()} />
          </PageFooter>
        </>
      )}
    </Container>
  );
};

export default Home;

const initialState = {
  user: {
    id: "",
    name: "",
    admin: false,
    mt: false,
  },
  currentCard: null,
};

const contextReducer = (state = initialState, action) => {
  switch (action.type) {
    case "@context/UPDATE":
      return {
        ...state,
        [action.payload.context]: action.payload.value,
      };
    default:
      return state;
  }
};

export default contextReducer;

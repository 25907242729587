import axios from "axios";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Button from "@mui/material/Button";

import { Container, Item, Title, SubTitle, ItemTitle } from "./styles";

const Review = ({ data, onConfirm }) => {

  const { REACT_APP_BASE_URL } = process.env;
  const { context } = useSelector((state) => state);
  const { currentCard } = context;

  async function handleConfirm(id) {
    await axios.post(`${REACT_APP_BASE_URL}/refuel/${id}`, {
      trip_id: id,
      km: data.km,
      lts: parseFloat(data.lts),
      mtc: parseFloat(data.mtc),
      price: parseFloat(data.price),
      fuel_type: data.type
    });
    onConfirm()
  }

  return (
    <Container>
      <Title>Confirme as informações abaixo:</Title>
      <br />
      <Item>
        <ItemTitle>Quilometragem atual:</ItemTitle>
        <SubTitle>{data.km}</SubTitle>
      </Item>
     {data.type === "gas" && <Item>
        <ItemTitle>Litros abastecidos:</ItemTitle>
        <SubTitle>{data.lts}</SubTitle>
      </Item>}
      {data.type === "gnv" && <Item>
        <ItemTitle>M³ Abastecidos:</ItemTitle>
        <SubTitle>{data.mtc}</SubTitle>
      </Item>}
      <Item>
        <ItemTitle>Valor pago pelo combustível:</ItemTitle>
        <SubTitle>{data.formatted}</SubTitle>
      </Item>
      <br />
      <br />
      <Button
        variant="contained"
        onClick={() => handleConfirm(currentCard)}
        fullWidth={true}
      >
        Confirmar
      </Button>
      <br />
      <Link to="/" style={{ width: "100%", textDecoration: "none" }}>
        <Button
          variant="contained"
          color="error"
          onClick={() => {}}
          fullWidth={true}
        >
          Cancelar
        </Button>
      </Link>
    </Container>
  );
};

export default Review;

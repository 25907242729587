import styled from "styled-components";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1365px) {
    transform: scale(0.99);
  }

  .fadein {
    width: 100%;

    @media (min-width: 1365px) {
      width: 30rem;
      padding: 0rem 1.5rem;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: "center";
  align-items: "center";
  flex-direction: column;
  box-sizing: border-box;
  padding: 1.6rem;
  padding-top: 5rem;
  .date-picker {
    font-size: 2pt !important;
  }
`;

export const PageHeader = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: #282828;
  padding: 0 0.5rem;
  padding-bottom: 1rem;

  span {
    margin-top: 0.5rem;
  }
`;

export const Title = styled.span`
  font-family: roboto;
  font-size: ${({ fontSize }) => fontSize || "1.2rem"};
  font-weight: bold;
  align-content: center;
  text-align: center;
  vertical-align: middle;
  color: #d82b5f;
`;

export const SubTitle = styled.span`
  font-size: ${({ fontSize }) => fontSize || "0.8rem"};
  padding-bottom: 0.5rem;
  span {
    font-weight: 600;
  }
`;

export const TextField = styled.textarea`
  min-height: 4rem;
  font-family: roboto;
  border-color: #2ab6f5;
  resize: none;
  border-radius: 0.25rem;
  margin-top: 1rem;
  box-sizing: border-box;
  padding: 0.5rem;

  &:focus {
    outline: none;
  }
`;

export const TextInput = styled.input`
  min-height: 2rem;
  font-family: roboto;
  border-color: #2ab6f5;
  resize: none;
  border-radius: 0.25rem;
  margin-top: 1rem;
  box-sizing: border-box;
  padding: 0.5rem;
  &:focus {
    outline: none;
  }
`;

export const ConfirmButton = styled.button`
  width: 100%;
  height: 2.4rem;
  background-color: #0056ea;
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  &:active {
    background-color: #003da5;
  }

  &:disabled {
    background-color: #6481bc;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const Dropdown = styled.select`
  width: 100%;
  height: 2rem;
  border-radius: 0.25rem;
  margin-right: 0.2rem;
  background-color: #fff;
  border-color: #2ab6f5;

  &:focus {
    border-color: #2ab6f5;
    outline: none;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 1.7rem;
  border-radius: 0.25rem;
  margin-right: 0.2rem;
  background-color: #fff;
  border-color: #2ab6f5;
  outline: none;
  border-width: 0.1rem;
  &:focus {
    border-color: #2ab6f5;
    outline: none;
  }
`;

import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateTrip } from "../../../../store/modules/trips/actions";

import Button from "@mui/material/Button";

import { Container, Item, Title, SubTitle, ItemTitle } from "./styles";

const Review = ({ data, onConfirm }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { REACT_APP_BASE_URL } = process.env;
  const { context } = useSelector((state) => state);
  const { currentCard } = context;

  async function handleConfirm(id) {
    dispatch(
      updateTrip(id, {
        status: "approved",
        initial_km: data.km,
        start_date: new Date(),
      })
    );

    await axios.put(`${REACT_APP_BASE_URL}/trip/${id}`, {
      status: "approved",
      initial_km: parseInt(data.km),
      start_date: new Date(),
    });

    if (data.occurrence) {
      await axios.post(`${REACT_APP_BASE_URL}/occurrences`, {
        trip_id: currentCard,
        description: data.description,
      });
    }

    onConfirm();
  }

  return (
    <Container>
      <Title>Confirme as informações abaixo:</Title>
      <br />
      <Item>
        <ItemTitle>Quilometragem atual:</ItemTitle>
        <SubTitle>{data.km}</SubTitle>
      </Item>
      <Item>
        <ItemTitle>O veículo apresenta defeito ou avaria?</ItemTitle>
        <SubTitle>{data.occurrence ? "Sim" : "Não"}</SubTitle>
      </Item>
      {data.occurrence && (
        <Item>
          <ItemTitle>Descrição da ocorrência:</ItemTitle>
          <SubTitle>{data.description}</SubTitle>
        </Item>
      )}
      <br />
      <br />
      <Button
        variant="contained"
        onClick={() => handleConfirm(currentCard)}
        fullWidth={true}
      >
        Confirmar
      </Button>
      <br />
      <Link to="/" style={{ width: "100%", textDecoration: "none" }}>
        <Button
          variant="contained"
          color="error"
          onClick={() => {}}
          fullWidth={true}
        >
          Cancelar
        </Button>
      </Link>
    </Container>
  );
};

export default Review;

import FadeIn from "react-fade-in/lib/FadeIn";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { Wrapper, Container, PageHeader, Subtitle, Title } from "./styles";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetCars } from "../../../store/modules/cars/actions";
import { AiFillEdit } from "react-icons/ai";
import axios from "axios";

import { useNavigate } from "react-router-dom";

const ListCars = () => {
  const [code, setCode] = useState(3);
  const [cars, setCars] = useState([]);

  const { REACT_APP_BASE_URL } = process.env;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function loadCars() {
      dispatch(resetCars());
      const { data } = await axios.get(`${REACT_APP_BASE_URL}/cars`);

      setCars(data);
    }

    loadCars();
  }, []);

  return (
    <Container>
      <Wrapper>
        <FadeIn className="fadein">
          <PageHeader>
            <Title>Veículos</Title>
            <Subtitle>Lista de veículos</Subtitle>
          </PageHeader>
          <br />
          <FormControl fullWidth={true}>
            <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={code}
              label="Empresa"
              placeholder="Empresa"
              onChange={(e) => setCode(e.target.value)}
              size="small"
              fullWidth={true}
            >
              <MenuItem value={1}>TV Cabo Branco - JP</MenuItem>
              <MenuItem value={2}>TV Paraíba - CG</MenuItem>
              <MenuItem value={3}>Todos</MenuItem>
            </Select>

            <Paper
              style={{
                height: "calc(100vh - 300px)",
                overflowY: "auto",
                overflowX: "hidden",
                borderBottom: "transparent",
                boxShadow: "none",
              }}
            >
              <List>
                {cars.map((car) => {
                  return (
                    car.code === code && (
                      <ListItem key={car.id}>
                        <ListItemText primary="Modelo" secondary={car.model} />
                        <ListItemText primary="Placa" secondary={car.plate} />
                        <ListItemText
                          primary="Empresa"
                          secondary={
                            car.code === 1
                              ? "TV Cabo Branco"
                              : "TV Paraíba - CG"
                          }
                        />
                        &nbsp;
                        <AiFillEdit
                          color="#000"
                          onClick={() => {
                            navigate(`/car/edit`, { state: { car } });
                          }}
                        />
                      </ListItem>
                    )
                  );
                })}
                {code === 3 &&
                  cars.map((car) => {
                    return (
                      <ListItem key={car.id}>
                        <ListItemText primary="Modelo" secondary={car.model} />
                        <ListItemText primary="Placa" secondary={car.plate} />
                        <ListItemText
                          primary="Empresa"
                          secondary={
                            car.code === 1
                              ? "TV Cabo Branco"
                              : "TV Paraíba - CG"
                          }
                        />
                        &nbsp;
                        <AiFillEdit
                          color="#000"
                          onClick={() => {
                            navigate(`/car/edit`, { state: { car } });
                          }}
                        />
                      </ListItem>
                    );
                  })}
              </List>
            </Paper>
          </FormControl>

          <Button
            variant="contained"
            onClick={() => navigate("/car/create")}
            fullWidth
          >
            Adicionar
          </Button>

          <Button
            variant="contained"
            onClick={() => navigate("/")}
            fullWidth
            color="error"
            style={{ marginTop: "10px" }}
          >
            Cancelar
          </Button>
        </FadeIn>
      </Wrapper>
    </Container>
  );
};

export default ListCars;

import { useState } from "react";
import { Link } from "react-router-dom";
import FadeIn from "react-fade-in";

import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { Container, Wrapper, PageHeader, Title, Subtitle } from "./styles";

import Success from "./success";
import Review from "./review";

const Checkout = () => {
  const [isOcurrence, setIsOcurrence] = useState(false);
  const [ocurrence, setOcurrence] = useState("");
  const [mileage, setMileage] = useState("");

  const [mode, setMode] = useState("checkout");

  return (
    <Container>
      <FadeIn>
        {mode === "review" && (
          <Review
            data={{
              km: mileage,
              ocurrence: isOcurrence,
              description: ocurrence,
            }}
            onConfirm={() => setMode("success")}
          />
        )}
        {mode === "success" && <Success />}
        {mode === "checkout" && (
          <Wrapper>
            <PageHeader>
              <Title>Fazer Checkout</Title>
              <br />
              <Subtitle>
                Para finalizar sua viagem, insira as informações abaixo:
              </Subtitle>
              <br />
              <br />
            </PageHeader>
            <TextField
              label="Quilometragem atual do veículo"
              fullWidth={true}
              type="number"
              onChange={(e) => setMileage(e.target.value)}
              autoComplete="off"
            />
            <br />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                O veículo apresenta defeito ou avaria?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={isOcurrence}
                label="O veículo apresenta defeito ou avaria?"
                placeholder="O veículo apresenta defeito ou avaria?"
                onChange={(e) => setIsOcurrence(e.target.value)}
                fullWidth={true}
              >
                <MenuItem value={true}>Sim</MenuItem>
                <MenuItem value={false}>Não</MenuItem>
              </Select>
            </FormControl>
            <br />

            {isOcurrence && (
              <TextField
                id="outlined-multiline-static"
                label="Observação"
                multiline
                rows={4}
                fullWidth={true}
                maxLength={150}
                maxRows={4}
                onChange={(e) => setOcurrence(e.target.value)}
                autoComplete="off"
              />
            )}

            <br />
            <Button
              variant="contained"
              onClick={() => setMode("review")}
              fullWidth={true}
              disabled={
                mileage === "" || (isOcurrence === true && ocurrence === "")
              }
            >
              Finalizar
            </Button>
            <br />
            <Link to="/" style={{ width: "100%", textDecoration: "none" }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => {}}
                fullWidth={true}
              >
                Cancelar
              </Button>
            </Link>
          </Wrapper>
        )}
      </FadeIn>
    </Container>
  );
};

export default Checkout;

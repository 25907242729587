import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .col {
    display: flex;
    flex-direction: row;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: "center";
  align-items: "center";
  flex-direction: column;
  box-sizing: border-box;
  padding: 1.6rem;
  .date-picker {
    font-size: 2pt !important;
  }
`;

export const PageHeader = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: #282828;
  padding: 0 0.5rem;
  padding-bottom: 1rem;
`;

export const Title = styled.span`
  font-family: roboto;
  font-size: ${({ fontSize }) => fontSize || "1.2rem"};
  font-weight: bold;
  align-content: center;
  text-align: center;
  vertical-align: middle;
  color: #d82b5f;
`;

export const Subtitle = styled.span`
  font-size: ${({ fontSize }) => fontSize || "0.9rem"};
  padding-bottom: 2rem;
  span {
    font-weight: 600;
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease-in-out;
  z-index: 2000;
  overflow-y: hidden;

  .sidebar {
    width: 13rem;
    transition: 0.5s;
    padding-top: 2rem;
    box-sizing: border-box;
  }

  .btn {
    background-color: transparent;
    color: #000;
    box-shadow: 0px 0px 0px;
    font-size: 0.7rem;
    margin-top: 0.5rem;
    border-radius: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    :active {
      background-color: #eaeaea;
    }

    span {
      margin-top: 0.2rem;
      margin-left: 1rem;
    }

    .icon {
      margin-left: 1rem;
      height: 2rem;
    }
  }
`;

export const Container = styled.div`
  width: 15rem;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  z-index: 1;
  left: 0;
  transition: all 0.3s ease-in-out;
`;

export const Header = styled.div`
  width: 100%;
  height: 3.5rem;
`;

export const Button = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:active {
    background-color: #f8f8f8;
  }
`;

/* eslint-disable */
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FadeIn from "react-fade-in/lib/FadeIn";
import { useNavigate, useLocation } from "react-router-dom";

import { Wrapper, Container, PageHeader, Subtitle, Title } from "./styles";
import { useState } from "react";
import { useEffect } from "react";

const EditUser = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = location.state;

  const [code, setCode] = useState(user.username.split("-")[0]);

  const [firstname, setFirstname] = useState(user.firstname);
  const [lastname, setLastname] = useState(user.lastname);
  const [admin, setAdmin] = useState(user.admin);
  const [mt, setMt] = useState(user.mt);
  const [username, setUsername] = useState(user.username.split("-")[1]);

  const { REACT_APP_BASE_URL } = process.env;

  async function handleConfirm() {
    const changedUser = {
      firstname,
      lastname,
      admin,
      mt,
      username: `${code}-${username}`,
    };

    var prompt = confirm("Tem certeza que deseja alterar os dados do usuário?");

    if (!prompt) {
      return;
    }

    const response = await axios.put(
      `${REACT_APP_BASE_URL}/user/${user.id}`,
      changedUser
    );

    if (response.status === 202) {
      alert("Usuário editado com sucesso!");
      navigate("/user/list");
    }
  }

  return (
    <Container>
      <Wrapper>
        <FadeIn>
          <PageHeader>
            <br />
            <br />
            <Title>Inspecionar usuário</Title>
            <br />
            <Subtitle>
              Edite as informações do usuário e confirme para salvar
            </Subtitle>
          </PageHeader>
          <br />
          <br />
          <div
            style={{
              display: "flex",
            }}
          >
            <TextField
              label="Nome"
              fullWidth={true}
              onChange={(e) => setFirstname(e.target.value)}
              autoComplete="off"
              size="small"
              focused={true}
              value={firstname}
            />
            &nbsp;&nbsp;
            <TextField
              label="Sobrenome"
              fullWidth={true}
              onChange={(e) => setLastname(e.target.value)}
              autoComplete="off"
              size="small"
              focused={true}
              value={lastname}
            />
          </div>

          <br />

          <FormControl fullWidth>
            <div className="col">
              <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={code}
                label="Empresa"
                placeholder="Empresa"
                onChange={(e) => setCode(e.target.value)}
                size="small"
                fullWidth
              >
                <MenuItem value={1}>TV Cabo Branco - JP</MenuItem>
                <MenuItem value={2}>TV Paraíba - CG</MenuItem>
              </Select>
              &nbsp;&nbsp;
            </div>
            <br />
            <FormControl fullWidth>
              <div className="col">
                <InputLabel id="demo-simple-select-label2">
                  Modo da conta
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label2"
                  id="demo-simple-select"
                  value={mt}
                  label="Modo da conta"
                  placeholder="Modo da conta"
                  onChange={(e) => setMt(e.target.value)}
                  size="small"
                  fullWidth
                >
                  <MenuItem value={false}>Comum</MenuItem>
                  <MenuItem value={true}>Motorista</MenuItem>
                </Select>
                &nbsp;&nbsp;
              </div>
            </FormControl>
            <br />
            <FormControl fullWidth>
              <div className="col">
                <InputLabel id="demo-simple-select-label2">
                  Adminstrador?
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label2"
                  id="demo-simple-select"
                  value={admin}
                  label="Modo da conta"
                  placeholder="Modo da conta"
                  onChange={(e) => setAdmin(e.target.value)}
                  size="small"
                  fullWidth
                >
                  <MenuItem value={true}>Sim</MenuItem>
                  <MenuItem value={false}>Não</MenuItem>
                </Select>
                &nbsp;&nbsp;
              </div>
            </FormControl>
            <br />

            {/* <TextField
              label="Matrícula"
              fullWidth={true}
              onChange={(e) => setUsername(e.target.value)}
              autoComplete="off"
              size="small"
              focused={true}
              value={username}
            /> */}
            {/* 
            <div>
              Gasolina
              <Switch />
              Pintura
              <Switch />
            </div> */}
          </FormControl>
          <br />
          <Button
            variant="contained"
            onClick={() => handleConfirm()}
            fullWidth={true}
            disabled={
              !firstname ||
              !lastname ||
              firstname.length < 3 ||
              lastname.length < 3
            }
          >
            Confirmar
          </Button>
          <br />
          <Button
            variant="contained"
            onClick={() => navigate("/user/list")}
            fullWidth={true}
            color="error"
          >
            Cancelar
          </Button>
        </FadeIn>
      </Wrapper>
    </Container>
  );
};

export default EditUser;

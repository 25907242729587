import Lottie from "react-lottie";
import success from "../../../../assets/img/success.json";
import { Container } from "./styles";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Success = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 3000);
  }, []);

  return (
    <Container>
      <Lottie
        height={200}
        width={200}
        speed={1.5}
        options={{
          animationData: success,
          loop: false,
          autoplay: true,
        }}
      />
    </Container>
  );
};

export default Success;

export const addCar = (car) => ({
  type: "@cars/ADD",
  payload: {
    car,
  },
});

export const deleteCar = (id) => ({
  type: "@cars/DELETE",
  payload: {
    id,
  },
});

export const resetCars = () => ({
  type: "@cars/RESET",
});

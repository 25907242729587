import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTrip } from "../../../store/modules/trips/actions";
import FadeIn from "react-fade-in";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AutoComplete from "../../widgets/autocomplete";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

import { Container, PageHeader, Title, Wrapper } from "./styles";

import { DatePicker } from "react-rainbow-components";

import { Link, useNavigate } from "react-router-dom";
import Review from "./review";
import Success from "./success";
import MenuItem from "@mui/material/MenuItem";
import { resetCars } from "../../../store/modules/cars/actions";

const Schedule = ({ handleClose }) => {
  const { context } = useSelector((state) => state);

  const [selectedCar, setSelectedCar] = useState("");

  const { REACT_APP_BASE_URL } = process.env;

  const [cars, setCars] = useState([]);

  const navigate = useNavigate();

  const [scheduleData, setScheduleData] = useState({
    start_date: new Date(),
    car: "",
    from: "",
    to: "",
    driver: `${context.user.firstname} ${context.user.lastname}`,
    status: "waiting",
    description: "",
    initial_km: "0",
    final_km: "0",
    end_date: null,
    occurrences: [],
    refuelling: [],
  });

  useEffect(() => {
    if (!context.user.id) {
      navigate("/");
    }
  }, [context.user.id]);

  const [modalContent, setModalContent] = useState("schedule");

  const dispatch = useDispatch();

  function handleConfirm() {
    if (modalContent === "schedule") {
      setModalContent("review");
    } else if (modalContent === "review") {
      setModalContent("success");

      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  }

  function handleChange(e) {
    setSelectedCar(e.target.value);
    console.log(e.target);
  }

  useEffect(() => {
    if (modalContent === "success") {
      insertNewTripOnDatabase(scheduleData);
    }
  }, [modalContent]);

  useEffect(() => {
    async function loadCars() {
      dispatch(resetCars());
      const data = await axios.get(`${REACT_APP_BASE_URL}/cars`);

      const filteredCars = data.data.filter(
        (car) => car.code == context.user.username.split("-")[0]
      );

      setCars(filteredCars);
    }

    loadCars();
  }, []);

  async function insertNewTripOnDatabase(data) {
    const newTrip = await axios.post(`${process.env.REACT_APP_BASE_URL}/trip`, {
      id: scheduleData.id,
      user_id: context.user.id,
      description: data.description,
      from: data.from || "Cobertura",
      to: data.to || "Rotativo",
      start_date: data.start_date,
      status: context.user.mt ? "pending" : data.status,
      car_id: context.user.mt ? selectedCar.split("_")[0] : null,
    });

    !context.user.mt &&
      dispatch(createTrip({ id: newTrip.data.id, ...scheduleData }));
    context.user.mt &&
      dispatch(
        createTrip({
          ...scheduleData,
          id: newTrip.data.id,
          from: scheduleData.from || "Cobertura",
          to: scheduleData.to || "Rotativo",
          status: context.user.mt ? "pending" : scheduleData.status,
          car: context.user.mt ? selectedCar.split("_")[1] : null,
        })
      );
  }

  return (
    <Container>
      <Wrapper>
        <FadeIn className="fadein">
          {modalContent === "success" && <Success />}

          {modalContent === "review" && (
            <Review onConfirm={setModalContent} data={scheduleData} />
          )}

          {modalContent === "schedule" && (
            <>
              <br />
              <PageHeader>
                <Title>- Solicitar Nova viagem -</Title>
                <span>Forneça as informações abaixo: </span>
                <br />
                <br />
              </PageHeader>
              {context.user.mt && (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Selecione o veículo
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCar}
                    label="Selecione o veículo"
                    placeholder="Selecione o veículo"
                    onChange={handleChange}
                    fullWidth={true}
                  >
                    {cars.map((car, index) => {
                      return (
                        <MenuItem
                          value={`${car.id}_${car.model} - ${car.plate}`}
                          key={index}
                        >
                          {car.model} - {car.plate}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              {!context.user.mt && (
                <DatePicker
                  id="datePicker-1"
                  value={scheduleData.start_date}
                  onChange={(d) => {
                    setScheduleData({
                      ...scheduleData,
                      start_date: d.toISOString(),
                    });
                  }}
                  label="DatePicker Label"
                  formatStyle="large"
                  locale="pt-BR"
                  isCentered={true}
                  hideLabel={true}
                  bottomHelpText="Selecione a data da viagem"
                  minDate={new Date()}
                />
              )}

              {!context.user.mt && (
                <AutoComplete
                  label="Local inicial"
                  onChange={(value) => {
                    setScheduleData({
                      ...scheduleData,
                      from: value,
                    });
                  }}
                />
              )}

              {!context.user.mt && (
                <AutoComplete
                  label="Local Final"
                  onChange={(value) => {
                    setScheduleData({
                      ...scheduleData,
                      to: value,
                    });
                  }}
                />
              )}
              <br />
              <br />
              <TextField
                id="outlined-multiline-static"
                label="Observação"
                multiline
                rows={4}
                fullWidth={true}
                maxLength={150}
                maxRows={4}
                onChange={(e) => {
                  setScheduleData({
                    ...scheduleData,
                    description: e.target.value,
                  });
                }}
              />
              <span
                style={{
                  color: "grey",
                  fontSize: ".7rem",
                  marginTop: ".2rem",
                }}
              >
                {!scheduleData.description && "Campo obrigatório"}
                {scheduleData.description.length > 0 &&
                  scheduleData.description.length < 5 &&
                  "Sua descrição deve ter no mínimo 10 caracteres"}
              </span>
              <br />
              <br />
              <Button
                variant="contained"
                onClick={() => handleConfirm()}
                fullWidth={true}
                disabled={
                  scheduleData.description === "" ||
                  scheduleData.description.length < 5 ||
                  (!context.user.mt &&
                    (scheduleData.date === "" ||
                      scheduleData.from === "" ||
                      scheduleData.to === "" ||
                      scheduleData.from.length < 3 ||
                      scheduleData.to.length < 3))
                }
                style={{
                  marginTop: ".6rem",
                }}
              >
                Finalizar
              </Button>
              <br />
              <br />
              <Link
                to="/"
                style={{
                  width: "100%",
                  textDecoration: "none",
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleConfirm()}
                  fullWidth={true}
                  style={{
                    marginTop: ".6rem",
                  }}
                >
                  Cancelar
                </Button>
              </Link>
            </>
          )}
        </FadeIn>
      </Wrapper>
    </Container>
  );
};

export default Schedule;

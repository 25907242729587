import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateTrip } from "../../../../store/modules/trips/actions";

import Button from "@mui/material/Button";

import { Container, Item, Title, SubTitle, ItemTitle } from "./styles";

const Review = ({ data, onConfirm }) => {
  const { REACT_APP_BASE_URL } = process.env;

  const dispatch = useDispatch();

  const { context } = useSelector((state) => state);
  const { currentCard } = context;

  async function handleConfirm(id) {
    await axios.put(`${REACT_APP_BASE_URL}/trip/${currentCard}`, {
      status: "finished",
      final_km: parseInt(data.km),
      end_date: new Date(),
    });

    if (data.ocurrence) {
      await axios.post(`${REACT_APP_BASE_URL}/occurrences`, {
        trip_id: currentCard,
        description: data.description,
      });
    }

    dispatch(
      updateTrip(id, {
        status: "finished",
        final_km: parseInt(data.km),
        end_date: new Date(),
      })
    );

    onConfirm();
  }

  return (
    <Container>
      <Title>Confirme as informações abaixo:</Title>
      <br />
      <Item>
        <ItemTitle>Quilometragem atual:</ItemTitle>
        <SubTitle>{data.km}</SubTitle>
      </Item>
      <Item>
        <ItemTitle>O veículo apresenta defeito ou avaria?</ItemTitle>
        <SubTitle>{data.ocurrence ? "Sim" : "Não"}</SubTitle>
      </Item>
      {data.ocurrence && (
        <Item>
          <ItemTitle>Descrição da ocorrência:</ItemTitle>
          <SubTitle>{data.description}</SubTitle>
        </Item>
      )}
      <br />
      <br />
      <Button
        variant="contained"
        onClick={() => handleConfirm(currentCard)}
        fullWidth={true}
      >
        Confirmar
      </Button>
      <br />
      <Link to="/" style={{ width: "100%", textDecoration: "none" }}>
        <Button
          variant="contained"
          color="error"
          onClick={() => {}}
          fullWidth={true}
        >
          Cancelar
        </Button>
      </Link>
    </Container>
  );
};

export default Review;

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 1.6rem;
`;

export const PageHeader = styled.div`
  width: 100%;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0 0.5rem;
  margin-top: 5rem;
  -webkit-box-shadow: 2px 13px 22px 3px rgba(255, 255, 255, 0.52);
  box-shadow: 2px 13px 22px 3px rgba(255, 255, 255, 0.52);
  z-index: 1000;
`;

export const Title = styled.span`
  font-family: roboto;
  font-size: 1.5rem;
  font-weight: bold;
`;

export const SubTitle = styled.span`
  font-size: 0.8rem;
  padding-bottom: 0.5rem;
  span {
    font-weight: 600;
  }
`;

export const PageFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  box-sizing: border-box;
  padding: 1rem 0.5rem;
  padding-top: 0rem;
  position: fixed;
  bottom: 0;
  left: 0;
  pointer-events: none;
`;

export const ScheduleModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 0 1.6rem;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const ScheduleContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 1rem;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
`;

export const TripsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const EmptyTrips = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 1.6rem;
  border-radius: 1rem;
  span {
    font-size: 1.2rem;
    text-align: center;
  }
`;

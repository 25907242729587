import { useState } from "react";
import { Link } from "react-router-dom";
import FadeIn from "react-fade-in";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Review from "./review";

import { Container, Wrapper, PageHeader, Title, Subtitle } from "./styles";

import IntlCurrencyInput from "react-intl-currency-input";

import Success from "./success";

const Refuel = () => {
  const [fuel, setFuel] = useState("");
  const [price, setPrice] = useState(0);
  const [formattedPrice, setFormattedPrice] = useState(0);
  const [mileage, setMileage] = useState("");
  const [fuelType, setFuelType] = useState("");
  const [mode, setMode] = useState("refuel");

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const handleChange = (event, value, maskedValue) => {
    event.preventDefault();
    setPrice(value);
    setFormattedPrice(maskedValue);
  };

  return (
    <Container>
      <FadeIn>
        {mode === "review" && (
          <Review
            data={{
              km: mileage,
              lts: fuelType === "gas" ? fuel : 0,
              mtc: fuelType === "gnv" ? fuel : 0,
              type: fuelType,
              price: price,
              formatted: formattedPrice,
            }}
            onConfirm={() => setMode("success")}
          />
        )}
        {mode === "success" && <Success />}
        {mode === "refuel" && (
          <Wrapper>
            <PageHeader>
              <Title>Registrar Abastecimento</Title>
              <br />
              <Subtitle>Preencha as informações abaixo:</Subtitle>
            </PageHeader>
            <br />
            <TextField
              label="Quilometragem atual do veículo"
              fullWidth={true}
              type="number"
              value={mileage}
              onChange={(e) => setMileage(e.target.value)}
              autoComplete="off"
            />
            <br />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Selecione o tipo de combustível
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={fuelType}
                label="Selecione o tipo de combustível"
                placeholder="Selecione o tipo de combustível"
                onChange={(e) => setFuelType(e.target.value)}
                fullWidth={true}
              >
                <MenuItem value="gas">Gasolina</MenuItem>
                <MenuItem value="gnv">GNV</MenuItem>
              </Select>
            </FormControl>
            <br />
            {fuelType === "gas" && (
              <TextField
                label="Quantidade de litros abastecidos"
                fullWidth={true}
                type="number"
                value={fuel}
                onChange={(e) => setFuel(e.target.value)}
                autoComplete="off"
              />
            )}
            {fuelType === "gnv" && (
              <TextField
                label="Quantidade em m3 abastecidos"
                fullWidth={true}
                type="number"
                value={fuel}
                onChange={(e) => setFuel(e.target.value)}
                autoComplete="off"
              />
            )}
            <br />

            <IntlCurrencyInput
              currency="BRL"
              label="Valor pago pelo combustível"
              config={currencyConfig}
              onChange={handleChange}
              component={TextField}
              type="tel"
              inputMOde="numeric"
              fullWidth={true}
              max={15000}
              value={price}
            />
            <br />

            <Button
              variant="contained"
              onClick={() => setMode("review")}
              fullWidth={true}
              disabled={
                mileage === "" || fuel === "" || price <= 0 ? true : false
              }
            >
              Finalizar
            </Button>

            <br />
            <Link to="/" style={{ width: "100%", textDecoration: "none" }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => {}}
                fullWidth={true}
              >
                Cancelar
              </Button>
            </Link>
          </Wrapper>
        )}
      </FadeIn>
    </Container>
  );
};

export default Refuel;

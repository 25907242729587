import { useDispatch, useSelector } from "react-redux";
import { BiMenu } from "react-icons/bi";
import { AiOutlineBell } from "react-icons/ai";

import { Container, Button, LogoContainer, Logo } from "./styles";

import { updateContext } from "../../../store/modules/context/actions";

const Header = () => {
  const dispatch = useDispatch();
  const { sidenav } = useSelector((state) => state.context);

  return (
    <Container>
      <Button onClick={() => dispatch(updateContext('sidenav', !sidenav)) }>
        <BiMenu color="#242424" size={22} />
      </Button>
      <LogoContainer>
        <Logo />&nbsp;<span>Transporte</span>
      </LogoContainer>
      <Button>
        <AiOutlineBell color="#242424" size={22} />
      </Button>
    </Container>
  );
};

export default Header;

import styled from "styled-components";
import logo from "../../../assets/img/logo.png";
import marcas from "../../../assets/img/reguamarcas.png";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  .loginbtn {
    background: linear-gradient(to right, #f98c2e, #fa3675, #ce37d8);

    :disabled {
      background: #eaeaea;
    }
  }

  h4 {
    margin-top: 1rem;
  }

  .input-row {
    display: flex;
    flex-direction: row;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .input-field[type="number"] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Logo = styled.div`
  width: 3rem;
  height: 3rem;
  background-image: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 1rem;
`;

export const MarcasWrapper = styled.div`
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Marcas = styled.div`
  width: 20rem;
  height: 2.2rem;
  background-image: url(${marcas});
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 4rem;
`;

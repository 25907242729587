import styled from "styled-components";

export const Container = styled.div`
  width: 3.8rem;
  height: 3.8rem;
  display: flex;
  background-color: #3722d6;
  border-radius: 50rem;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;

  &:active {
    background-color: #6c5dd3;
  }

  pointer-events: all;
`;

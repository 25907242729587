import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { Container, Item, Title, SubTitle, ItemTitle } from "./styles";

const Review = ({ onConfirm, data }) => {
  const fullDate = new Date(data.start_date);
  const day = fullDate.getDate();
  const month = fullDate.getMonth();
  const year = fullDate.getFullYear();

  return (
    <Container>
      <Title>Confirme as informações abaixo:</Title>
      <br />
      <Item>
        <ItemTitle>Data:</ItemTitle>
        <SubTitle>{`${day < 10 ? `0${day}` : day}-${
          month < 10 ? `0${month + 1}` : month
        }-${year}`}</SubTitle>
      </Item>
      <Item>
        <ItemTitle>Trajeto:</ItemTitle>
        <SubTitle>
          {data.from && data.to ? `${data.from} - ${data.to}` : "Cobertura"}
        </SubTitle>
      </Item>
      <Item>
        <ItemTitle>Observação:</ItemTitle>
        <SubTitle>{data.description}</SubTitle>
      </Item>
      <br />
      <br />
      <Button
        variant="contained"
        onClick={() => onConfirm("success")}
        fullWidth={true}
      >
        Confirmar
      </Button>
      <br />
      <Link to="/" style={{ width: "100%", textDecoration: "none" }}>
        <Button
          variant="contained"
          color="error"
          onClick={() => {}}
          fullWidth={true}
        >
          Cancelar
        </Button>
      </Link>
    </Container>
  );
};

export default Review;

import axios from "axios";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import { updateTrip } from "../../../store/modules/trips/actions";
import { Link } from "react-router-dom";
import Success from "./success";

import FadeIn from "react-fade-in";

import { Wrapper, Container, PageHeader, Title, SubTitle } from "./styles";
import { useEffect, useState } from "react";
import { resetCars } from "../../../store/modules/cars/actions";

const Approve = (props) => {
  const { REACT_APP_BASE_URL } = process.env;

  const { context } = useSelector((state) => state);
  const [cars, setCars] = useState([]);

  const dispatch = useDispatch();

  const [selectedCar, setSelectedCar] = useState("");

  const [mode, setMode] = useState("approve");

  async function handleConfirm() {
    const car = cars.find((car) => car.id === selectedCar);

    await axios.put(`${REACT_APP_BASE_URL}/trip/${context.currentCard}`, {
      car_id: selectedCar,
      status: "pending",
    });

    dispatch(
      updateTrip(context.currentCard, {
        status: "pending",
        car: `${car.model} - ${car.plate}`,
      })
    );

    setMode("success");
  }

  function handleChange(e) {
    setSelectedCar(e.target.value);
  }

  useEffect(async () => {
    const data = await axios.get(`${REACT_APP_BASE_URL}/cars`);

    setCars(data.data);
    console.log(cars);
  }, []);

  return (
    <Container>
      <Wrapper>
        <FadeIn>
          {mode === "approve" ? (
            <>
              <PageHeader>
                <Title>Aprovar solicitação</Title>

                <SubTitle>
                  Para aprovar a solicitação, insira o veículo que o solicitante
                  irá utilizar.
                </SubTitle>
              </PageHeader>
              <br />
              <br />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Selecione o veículo
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCar}
                  label="Selecione o veículo"
                  placeholder="Selecione o veículo"
                  onChange={handleChange}
                  fullWidth={true}
                >
                  {cars.map((car, index) => {
                    return (
                      <MenuItem value={car.id} key={index}>
                        {car.model} - {car.plate}
                      </MenuItem>
                    );
                  })}
                </Select>
                <br />
                <Button
                  variant="contained"
                  onClick={() => handleConfirm()}
                  fullWidth={true}
                  disabled={!selectedCar}
                >
                  Confirmar
                </Button>
                <br />
                <Link to="/" style={{ width: "100%", textDecoration: "none" }}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {}}
                    fullWidth={true}
                  >
                    Cancelar
                  </Button>
                </Link>
              </FormControl>
            </>
          ) : (
            <Success />
          )}
        </FadeIn>
      </Wrapper>
    </Container>
  );
};

export default Approve;

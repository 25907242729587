import FadeIn from "react-fade-in/lib/FadeIn";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { Wrapper, Container, PageHeader, Subtitle, Title } from "./styles";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetCars } from "../../../store/modules/cars/actions";
import { AiFillEdit } from "react-icons/ai";
import axios from "axios";

import { useNavigate } from "react-router-dom";

const Checklist = () => {
  const [code, setCode] = useState(3);
  const [selectedCar, setSelectedCar] = useState("");
  //   const [cars, setCars] = useState([]);

  // const { REACT_APP_BASE_URL } = process.env;

  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const state = useSelector((state) => state);
  const { cars } = state;

  //   useEffect(() => {
  //     async function loadCars() {
  //       dispatch(resetCars());
  //       const { data } = await axios.get(`${REACT_APP_BASE_URL}/cars`);
  //       setCars(data);
  //     }

  //     loadCars();
  //   }, []);

  function handleChange(e) {
    setSelectedCar(e.target.value);
  }

  return (
    <Container>
      <Wrapper>
        <FadeIn className="fadein">
          <PageHeader>
            <Title>Veículos</Title>
            <Subtitle>Lista de veículos</Subtitle>
          </PageHeader>
          <br />
          <FormControl fullWidth={true}>
            <InputLabel id="demo-simple-select-label">Veículo</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedCar}
              label="Veículo"
              onChange={handleChange}
              size="small"
              fullWidth={true}
            >
              {cars.data.map((car, index) => {
                return (
                  <MenuItem value={car.id} key={index}>
                    {car.model} - {car.plate}
                  </MenuItem>
                );
              })}
            </Select>

            <Paper
              style={{
                height: "calc(100vh - 300px)",
                overflowY: "auto",
                overflowX: "hidden",
                borderBottom: "transparent",
                boxShadow: "none",
              }}
            ></Paper>
          </FormControl>

          <Button variant="contained" onClick={() => {}} fullWidth>
            Atualizar
          </Button>

          <Button
            variant="contained"
            onClick={() => navigate("/")}
            fullWidth
            color="error"
            style={{ marginTop: "10px" }}
          >
            Cancelar
          </Button>
        </FadeIn>
      </Wrapper>
    </Container>
  );
};

export default Checklist;
